/* Layout de base */
/* Layout de base */
.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;
}

/* Conteneur pour les blocs principaux */
.content-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    padding-bottom: 150px;
}

/* Conteneur pour le logo */
.logo-container {
    position: absolute;
    top: 10%;
    left: 20px;
    z-index: 1000;
}

.logo {
    width: 250px;
    height: auto;
    margin-bottom: 10px;
}


/* Conteneur du contenu principal */
.box-container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 60px;
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}


/* Conteneur du logo pour le mobile */
@media (max-width: 600px) {
    .logo-container {
        position: static;
        text-align: center;
        margin-bottom: 20px;
        width: 100%;
    }

    .logo {
        width: 100px;
        margin: 10px auto;
        display: block;
    }

    .box-container {
        margin-top: 20px;
        padding: 15px;
        width: calc(100% - 30px); /* Réduction de la largeur pour respecter le padding */
        margin-left: auto;
        margin-right: auto;
    }

    .content-container {
        padding: 0 15px; /* Ajout d'un padding à gauche et à droite pour mobile */
        padding-bottom: 100px; /* Ajustement de l'espacement en bas */
    }
}
