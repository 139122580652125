/* Navbar background color */
.navbar {
    background-color: black !important;
    height: 120px; /* Hauteur de la navbar */
    display: flex;
    align-items: center; /* Centrage vertical du contenu */
    position: relative; /* Nécessaire pour positionner le logo */
    padding: 20px; /* Supprime tout padding par défaut */
    width: 100%; /* Occupe toute la largeur de l'écran */
}

.nav-title{
    padding-left: 25px !important;
    color: white;
}

/* Logo dans la navbar */
.logo-container-navbar {
    position: absolute; /* Positionne le logo absolument à gauche */
    left: 0; /* Complètement à gauche */
    display: flex;
    align-items: center;
    padding-left: 0; /* Assure qu'il n'y a pas de padding à gauche */
    text-decoration: none;
}

.navbar-logo {
    height: 100px; /* Ajustez la taille du logo ici */
    margin-left: 10px; /* Petit espace pour que le logo ne touche pas le bord */
}

/* Toolbar styles */
.toolbar-content {
    flex-grow: 1;
    display: flex;
    justify-content: center; /* Centrer les boutons du menu */
    align-items: center;
    padding: 0; /* Supprime le padding de la toolbar */
    width: 100%; /* Utilise toute la largeur */
}

/* Link styles */
.nav-link {
    text-decoration: none;
}

.nav-button {
    color: white !important;
    margin-right: 10px; /* Ajuste la marge entre les boutons */
    font-size: 1.2rem !important; /* Ajuste la taille de l'icône */

}

/* Menu button styles */
.menu-button {
    color: white !important;
    background-color: black; /* Fond noir */
    border-radius: 50%; /* Cercle parfait */
    padding: 10px; /* Espace interne pour agrandir le cercle */
    position: absolute; /* Position absolue pour contrôler l'emplacement */
    right: 10px; /* Place l'icône tout à droite */
    top: 20px; /* Ajuste la position verticale */
    z-index: 2; /* Assure que l'icône est au-dessus des autres éléments */
}


.icon-menu {
    color: white !important;
    font-size: 1.5rem; /* Ajuste la taille de l'icône */
    background-color: black; /* Fond noir */
    border-radius: 50%; /* Cercle parfait */
    padding: 10px; /* Espace interne pour agrandir le cercle */
}


/* Mobile view styles */
@media (max-width: 600px) {
    /* Réduire la taille du logo sur mobile */
    .navbar-logo {
        height: 40px; /* Ajustez la taille du logo pour mobile */
    }

    /* Link styles in the mobile menu */
    .nav-link,
    .nav-button {
        color: black !important;
        display: block;
        margin-bottom: 0;
        background-color: white !important;
        padding: 10px;
        text-align: center;
        transition: background-color 0.3s ease;
        width: 100%; /* Full width in mobile view */
    }

    /* Hover effect on links in mobile menu */
    .nav-link:hover,
    .nav-button:hover {
        background-color: lightgray !important;
    }

    /* Styling for the menu list */
    .MuiMenu-list {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    /* Divider inside the mobile menu */
    .MuiMenu-list > .MuiDivider-root {
        background-color: white;
        height: 1px;
    }

    /* Make sure the mobile menu takes the full width of the screen */
    .mobile-menu .MuiPaper-root {
        width: 100vw !important;
        left: 0 !important;
        right: 0 !important;
        top: 64px; /* Adjust based on AppBar height */
        max-width: none !important;
        border-radius: 0 !important;
    }

    /* Menu button when active (white version) */
    .nav-link-white {
        color: white !important;
        background-color: black !important;
    }

    .nav-button-white {
        background-color: black !important;
    }


}
