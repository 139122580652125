.footer {
    margin-top: auto; /* Pour pousser le footer en bas de la page */
    color: #fff !important;
    padding: 10px;
    text-align: center;
}

.footer-link {
    font-size: 14px;
    text-decoration: none;
    color: #fff; /* Couleur des liens */
}

.footer-link:hover {
    color: lightgray; /* Couleur des liens au survol */
}

.social-media-icons {
    display: inline-block; /* Afficher les icônes de réseaux sociaux en ligne */
    margin-left: 20px; /* Marge à gauche pour les icônes de réseaux sociaux */
}

.social-media-icon {
    width: 40px; /* Largeur des icônes */
    height: 40px; /* Hauteur des icônes */
    margin: 0 10px; /* Marge entre les icônes */
    fill: #fff !important; /* Couleur de remplissage des icônes */
    transition: fill 0.3s; /* Transition de couleur de remplissage */
}

.social-media-icon:hover {
    fill: lightgray !important; /* Couleur de remplissage des icônes au survol */
}
